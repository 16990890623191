import axios from "axios";

// styles scss
import { Col, Container, Row } from "react-bootstrap";
import MainHeading from "../../../Shared/MainHeading/index";
import MainButton from "../../../Shared/MainButton/index";
import MainInputField from "../../../Shared/MainInputField/index";
import ChangelangIcon from "../../../Shared/Icons/ChangelangIcon";
import BackButton from "../../../Shared/BackButton";
import { OverlayTrigger, Popover } from "react-bootstrap";
import mainLogo from "../../../Assets/Images/mainLogo.png";
import RatingStars from "../../../Shared/RatingStars";
import StarIcon from "../../../Shared/Icons/StarIcon";
import { useState } from "react";
import { baseUrl } from "../../../utilis/baseUrl";
import { store } from "../../../Store/store";
import { toast } from "react-toastify";
import ConfirmPayeeModal from "../../../Shared/ConfirmPayeeModal/ConfirmPayeeModal";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom/dist";
import NoRating from "../../../Assets/Images/iCons/norating.svg";
import { addConnectedId } from "../../../Store/Auth/slices";
import { getPlaceId } from "../../../Store/TeamMembers/slices";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./styles.scss";
const TipConformation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const payeeCodeRef = useRef(null);
  const popoverTop = (
    <Popover id="popover-positioned-left" title="Popover left">
      <Popover.Body>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>It takes minimum 5 reviews to reveal the overall rating</p>
        </div>
      </Popover.Body>
    </Popover>
  );
  let { t } = useTranslation();
  const token = store?.getState()?.Auth?.user?.accessToken;
  const user = store?.getState()?.Auth?.user?.user;
  // const baseUrl = process.env.REACT_APP_BASE_URL;

  // const token =
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiaWF0IjoxNjg5MzI2MDc0fQ.3I2xXeO2CGZhRFYM_U295ZaiF_Rd-vx6BjTZAXQDH-Q';
  const [show, setShow] = useState(false);
  const [payeeCode, setPayeeCode] = useState("");
  const [payee, setPayee] = useState(null);
  const [payeeCodeError, setPayeeCodeError] = useState("");

  const handleClose = () => {
    setShow((state) => false);
    // navigate('/CalculateTip', { state: { payeeCode, payeeId: payee.id } });
  };
console.log({payee})
  const handleConfirm = async () => {
    setShow((state) => false);

    const connectData = await axios.get(
      `${baseUrl}/integration/getstripeConnectedId?userId=${payee?.id}&toTeamId=${payee?.teamId}`
    );
    console.log(connectData?.data?.data?.user?.stripeConnectedAccountId);
    dispatch(
      addConnectedId(connectData?.data?.data?.user?.stripeConnectedAccountId)
    );

    if (user) {
      localStorage.setItem("tip", "");
    }

    navigate("/CalculateTip", {
      state: { payeeCode, payeeId: payee?.id, payee },
    });
  };

  const handleShow = async (e) => {
    console.log("payee code in function--->", payeeCode);
    e.preventDefault();
    if (!payeeCode || payeeCode === "") {
      setPayeeCodeError(t("Please enter unique ID"));
      return;
    }
    try {
      const { data } = await axios.get(
        `${baseUrl}/payee/payee-details/${payeeCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPayee(data?.data?.payee);
      dispatch(getPlaceId(data?.data?.payee?.placeId))
      localStorage.setItem("placeId", data?.data?.payee?.placeId);
      setShow(true);
    } catch (err) {
      toast.error(err?.response?.data?.data?.message, {
        autoClose: 5000,
      });
    }
  };
  // const handlePayeeCode = (e) => {
  //   setPayeeCode(e.target.value)
  // }

  const validatePayeeCode = (payeeCode) => {
    const regex = /^[0-9]{1,8}$/;
    return regex.test(payeeCode);
  };

  const handlePayeeCode = (e) => {
    const inputValue = e.target.value;

    if (/^[0-9]{0,8}$/.test(inputValue)) {
      setPayeeCode(inputValue);
      setPayeeCodeError("");
    } else {
      if (payeeCode?.length === 8) {
        setPayeeCodeError(null);
      } else {
        setPayeeCodeError("Please enter a number with a maximum of 8 digits.");
      }
    }
  };
  useEffect(() => {
    if (payeeCodeRef?.current) {
      payeeCodeRef?.current?.focus();
    }
  }, [payeeCodeRef]);
  console.log("payee --->", payee);
  console.log("payee code --->", payeeCode);

  return (
    <>
      <Container fluid className="h-100">
        <Row className="vh-100">
          <Col md="12" className="d-md-none d-block">
            <div className="p-2">
              <div className="backHome">
                <BackButton />
              </div>
            </div>
          </Col>
          <div className="h-100 w-100 d-flex text-start justify-content-center align-items-center ">
            <Col xxl={3} lg={4} md={7} sm={7} xs={10} className="text-center">
              <div className="img mb-5">
                <img
                  src={mainLogo}
                  className="img-fluid"
                  alt=""
                  style={{ maxWidth: "50%", height: "auto" }}
                />
              </div>
              <form onSubmit={handleShow}>
                <MainHeading
                  heading={t("Enter Unique ID")}
                  color="textGreen"
                  className={`text-center`}
                />
                <MainInputField
                  type={"text"}
                  name={"payeeCode"}
                  id={"payeeCode w-100"}
                  placeholder={t("Enter Unique ID")}
                  className=" w-100"
                  autocomplete="none"
                  state={"search"}
                  onHandleChange={handlePayeeCode}
                  value={payeeCode}
                  shouldFocus={true}
                />

                {payeeCodeError && (
                  <small className="text-danger d-block w-100 text-start">
                    {payeeCodeError}
                  </small>
                )}

                <div className="TipConformation">
                  <button
                    className="mainButton mt-3"
                    text={t("Confirm")}
                    variant="primary"
                    onClick={handleShow}
                  >
                    {t("Confirm")}
                  </button>
                </div>
              </form>
            </Col>
          </div>
        </Row>
      </Container>
      {payee && (
        <ConfirmPayeeModal
          payee={payee}
          payeeCode={payeeCode}
          handleClose={handleClose}
          show={show}
          confirm="Confirm"
          cancel="Cancel"
          handleConfirm={handleConfirm}
        >
          <Row className="justify-content-center p-0 m-0">
            <div
              className="text-white d-flex align-items-center py-0"
              style={{ borderRadius: "10px" }}
            >
              <div className="d-flex align-items-center justify-content-between py-0 w-100">
                <div className="d-flex justify-content-center align-items-center py-3">
                  <div
                    className="rounded-circle me-2"
                    style={{
                      width: "60px",
                      height: "60px",
                      backgroundImage: `url(${payee.profileImg})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </div>
                <div className="font-weight-bold ms-2 h4">{payee.username}</div>
                <div className=" d-flex align-items-center justify-content-end w-100">
                  <div className=" d-flex align-items-center">
                    <>
                      <div className="">
                        {/* <StarIcon /> */}
                        {payee?.reviewsCount >= 5 ? (
                          <RatingStars
                            value={payee?.rating}
                            text={payee?.rating}
                            color="#FFB33E"
                          />
                        ) : (
                          <>
                            <OverlayTrigger
                              trigger={["hover"]}
                              placement="top"
                              overlay={popoverTop}
                            >
                              <img
                                className="no-rating"
                                src={NoRating}
                                alt="no-rating"
                              />
                            </OverlayTrigger>
                          </>
                        )}
                      </div>
                      {/* <div className=" small font-weight-bold">
                          {payee?.rating}
                        </div> */}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </ConfirmPayeeModal>
      )}
    </>
  );
};

export default TipConformation;
