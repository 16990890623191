import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import UserImage from "../../Assets/Images/AccountImage.png";
import Button from "react-bootstrap/Button";
import "./confirmPayeeModal.scss";
import { useTranslation } from "react-i18next";
const ConfirmationModal = ({
  className,
  show,
  handleClose,
  payee,
  teamOwner,
  title1,
  title2,
  onClick,
  disabled,
  payeeCode,
  children,
  confirm,
  cancel,
  title,
}) => {
  let { t } = useTranslation();
  return (
    <Modal
      className={`${className} modal-dialog-centered`}
      show={show}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title1 && (
        <Modal.Header className="border-0 pb-0 ">
          <Modal.Title className="text-center w-100 text-white">
            {title1} <span className="textGreen fw-bold">{teamOwner}</span>{" "}
            {title2}
          </Modal.Title>
        </Modal.Header>
      )}

      {children}

      <Modal.Footer className="FooterModal p-0">
        <div className="FooterModalCon d-flex align-items-center w-100">
          <div className="w-100">
            <Button
              className=" btn text-white btn-right m-0 w-100"
              disabled={disabled ?? false}
              variant="secondary"
              onClick={() => {
                onClick();
                handleClose();
              }}
            >
              {t("Yes")}
            </Button>
          </div>
          <div className="w-100">
            <Button
              className="btn btn-left m-0 w-100"
              variant="secondary"
              onClick={handleClose}
            >
              {t("No")}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
